import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faPhone, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrganizationModel } from '@nimles/models';
import {
  Column,
  Container,
  Reddit,
  Row,
  Section,
  Share,
} from '@nimles/react-web-components';
import React from 'react';
import { getImageUrl } from '../../../utils';
import { SocialLink } from '../../social/SocialLink';

const BannerSection = styled(Section)`
  min-height: 80vh;
  padding: 0;
  color: #fff;

  h1,
  h2 {
    color: #fff;
    margin-bottom: 0;
    text-align: center;
  }
`;

interface Props {
  organization: OrganizationModel;
  location: Location;
}

export const OrganizationBanner = ({
  organization,
  location: { href },
}: Props) => {
  const theme = useTheme();

  const {
    name,
    bannerId,
    website,
    phoneNumbers,
    emails,
    address: { city },
  } = organization;

  const banner = getImageUrl(bannerId, 1920);

  const BannerTextContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    h1,
    h2 {
      margin: 0;
      padding: 20px 0;
    }
  `;

  return (
    <BannerSection backgroundImage={banner}>
      <Section flex>
        <Container flex justify="center" padding="100px 15px 0">
          <BannerTextContainer>
            <h1>{name}</h1>
            <h2>{city}</h2>
          </BannerTextContainer>
        </Container>
        <Container>
          <Row wrap="wrap" justify="flex-end" align="flex-end">
            <Column>
              <Share
                href={href}
                title={name}
                color={theme.banner.primary.onColor}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </BannerSection>
  );
};
