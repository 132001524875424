import styled from '@emotion/styled';

export const SocialIcon = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: ${({ background }) => background};
  border: 1px solid #ffffff;
  margin: 0 6px;
  padding: 10px;
  width: 50px;
  height: 50px;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    margin: 0 20px;
  }
`;
