import { OrganizationModel } from '@nimles/models';
import {
  Section,
  Container,
  Row,
  Column,
  Card,
  CardBody,
} from '@nimles/react-web-components';
import { Marker } from '@react-google-maps/api';
import React from 'react';
import { getImageUrl, socialArray } from '../../../utils';
import { Map, toLatLng } from '../../map/Map';
import { Tag } from '../../Tag';
import { Text } from '../../Text';
import marker from '../../../images/marker.svg';
import styled from '@emotion/styled';
import { OrganizationBanner } from '../banner/OrganizationBanner';
import { SocialLink } from '../../social/SocialLink';
import { SocialIcon } from '../../social/SocialIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@emotion/react';
import { OrganizationGrid } from '../grid/OrganizationGrid';

const Capitalized = styled.span`
  text-transform: capitalize;
`;

const Logo = styled.img`
  margin-bottom: 30px;
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

interface Props {
  organization: OrganizationModel;
  organizationsNearby: OrganizationModel[];
  location: Location;
}

const ContactTextColumn = styled(Column)`
  order: -1;
  /* padding: ${({ theme }) => theme.default.padding}px; */
  width: 100%;
  
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    order: 0;
    width: auto;
    padding-top: 60px;
  }
`;

const ContactSideColumn = styled(Column)`
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    padding: 60px 15px 0;
    flex: 1;
  }
`;

export const OrganizationPage = ({
  organization,
  organizationsNearby,
  location,
}: Props) => {
  const theme = useTheme();

  const {
    name,
    description,
    logoId,
    website,
    tags,
    phoneNumbers,
    emails,
    socialLinks,
    address: { street, postalCode, city },
  } = organization;

  return (
    <>
      <OrganizationBanner organization={organization} location={location} />
      <Section padding>
        <Container>
          <Row wrap="wrap">
            <Column xs={100} md={50} lg={60} justify="center">
              <Text>
                {logoId ? (
                  <Row justify="center">
                    <Column flex>
                      <Logo src={getImageUrl(logoId, 480)} alt={name} />
                    </Column>
                  </Row>
                ) : null}
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Text>
            </Column>
            <Column xs={100} md={50} lg={40} justify="center">
              {organization.location ? (
                <Card flex>
                  <CardBody>
                    <Map center={organization.location}>
                      <Marker
                        position={toLatLng(organization.location)}
                        icon={marker}
                      />
                    </Map>
                  </CardBody>
                  <CardBody>
                    <Row justify="center">
                      <Column align="center">
                        <div>{street}</div>
                        <div>
                          {postalCode} {city}
                        </div>
                      </Column>
                    </Row>
                  </CardBody>
                </Card>
              ) : null}
            </Column>
          </Row>
        </Container>
      </Section>
      <Section padding>
        <Container
          background={theme.colors.surface.color}
          color={theme.colors.surface.onColor}
        >
          <Row wrap="wrap">
            <ContactSideColumn>
              {website ? (
                <SocialLink
                  rel="nofollow"
                  href={
                    website.indexOf('http') === 0
                      ? website
                      : `http://${website}`
                  }
                  target="_blank"
                >
                  <SocialIcon background="#999">
                    <FontAwesomeIcon icon={faHome} size="lg" />
                  </SocialIcon>
                  {website.indexOf('http') === 0
                    ? new URL(website).hostname
                    : website}
                </SocialLink>
              ) : null}

              {emails
                ? emails.map((email) => (
                    <SocialLink
                      key={email}
                      href={`mailto:${email}`}
                      target="_blank"
                    >
                      <SocialIcon background="#999">
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                      </SocialIcon>
                      {email}
                    </SocialLink>
                  ))
                : null}

              {phoneNumbers
                ? phoneNumbers?.map((phone) => (
                    <SocialLink
                      key={phone}
                      href={`tel:${phone}`}
                      target="_blank"
                    >
                      <SocialIcon background="#999">
                        <FontAwesomeIcon icon={faPhone} size="lg" />
                      </SocialIcon>
                      {phone}
                    </SocialLink>
                  ))
                : null}
              {socialArray.map((s) => {
                const social = socialLinks?.[s.name];

                return social ? (
                  <SocialLink key={s.name} href={social} target="_blank">
                    <SocialIcon background={s.background}>
                      <FontAwesomeIcon icon={s.icon} size="lg" />
                    </SocialIcon>
                    <Capitalized>{s.name}</Capitalized>
                  </SocialLink>
                ) : null;
              })}
            </ContactSideColumn>
            <ContactTextColumn align="center" justify="center">
              <h2>Här når du oss</h2>
            </ContactTextColumn>
            <Column flex></Column>
          </Row>
        </Container>
      </Section>
      <Section padding>
        <Container>
          <Row justify="center" wrap="wrap" margin="0 -10px">
            {tags?.sort().map((tag) => (
              <Column>
                <Tag>{tag}</Tag>
              </Column>
            ))}
          </Row>
        </Container>
      </Section>
      <Section padding>
        <Container>
          <h2>I närheten av {name}</h2>
          <OrganizationGrid
            organizations={organizationsNearby}
            orderBy="popularity"
          />
        </Container>
      </Section>
    </>
  );
};
