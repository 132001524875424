import styled from '@emotion/styled';

export const Text = styled.div<{ center?: boolean }>`
  position: relative;
  text-align: ${({ center }) => (center ? 'center' : 'unset')};
  padding: 20px;
  h2 {
  }
  @media (max-width: 750px) {
    font-size: 16px;
    padding: 10px;
  }
`;
